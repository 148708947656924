import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from '../../../reusecore/src/elements/Box';
import Heading from '../../../reusecore/src/elements/Heading';
import Logo from '../../../reusecore/src/elements/UI/Logo';
import Container from '../../../common/src/components/UI/Container';
import FooterWrapper, { List, ListItem } from './footer.style';
import { Link } from "gatsby";
import LogoImage from '../../../common/src/assets/image/saas/logofooter.png';
import { Icon } from 'react-icons-kit';
import {twitter} from 'react-icons-kit/fa/twitter';
import {linkedin} from 'react-icons-kit/fa/linkedin';
import {facebook} from 'react-icons-kit/fa/facebook';

const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  titleStyle,
  logoStyle,
  textStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      allContentfulBlockSetting {
        edges {
          node {
            logo {
              fluid{
                src
              }
            }
            footerFacebookLink
            footerTwitterLink
            footerLinkedinLink
          }
        }
      }
      allContentfulNavItem(filter: {menuPosition: {nin: "Main Menu"}}) {
        group(field: menuPosition) {
          nodes {
            link
            caption
          }
          fieldValue
        }
      }
    }
  `);
const logosite = Data.allContentfulBlockSetting.edges[0].node;

  return (
    <FooterWrapper>
      <div className="topline"></div>
      <Container className="footer_container">
        <Box className="row" {...row}>
          <Box {...colOne}>
            <Logo
              href="#"
              logoSrc={LogoImage}
              title="Hosting"
              logoStyle={logoStyle}
              className="logofooter"
            />
          </Box>
          {/* End of footer logo column */}
          <Box {...colTwo}>
            {Data.allContentfulNavItem.group.map((widget, index) => (
              <Box className="col" {...col} key={`footer-widget-${index}`}>
                <Heading content={widget.fieldValue} className="footertitle" />
                <List>
                  {widget.nodes.map((item, index) => (
                    <ListItem key={`footer-list-item-${index}`}>
                      <Link to={item.link} className="ListItem">
                        {item.caption}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
          {/* End of footer List column */}
        </Box>
      </Container>
      <Container className="subfooter">
        <ul className="web-links">
          <li><Link to="/" className="homepage">Copyright © 2019 Controller</Link></li>
          <li><Link to="/terms">Terms of Use</Link></li>
        </ul>
        <ul className="social-links">
          <li><a rel="noopener noreferrer" href={logosite.footerTwitterLink} target="_blank"><Icon icon={twitter} size={22} /></a></li>
          <li><a rel="noopener noreferrer" href={logosite.footerLinkedinLink} target="_blank"><Icon icon={linkedin} size={22} /></a></li>
          <li><a rel="noopener noreferrer" href={logosite.footerFacebookLink} target="_blank"><Icon icon={facebook} size={22} /></a></li>
        </ul>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Footer col one style
  colOne: {
    width: [1, '35%', '35%', '23%'],
    mt: [0, '13px'],
    mb: ['30px', 0],
    pl: ['15px', 0],
    pr: ['15px', '15px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '65%', '65%', '77%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', '50%', '50%', '25%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
  // widget title default style
  titleStyle: {
    color: '#343d48',
    fontSize: '16px',
    fontWeight: '700',
    mb: '30px',
  },
  // Default logo size
  logoStyle: {
    width: '100px',
    mb: '0px',
    ml: '15px',
  },
  // widget text default style
  textStyle: {
    color: '#0f2137',
    fontSize: '16px',
    mb: '10px',
  },
};

export default Footer;
