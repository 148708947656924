import styled from 'styled-components';

const FooterWrapper = styled.footer`
    
   background-color: rgb(0, 21, 26);
  .topline{
    width: 100%;
    height: 4px;
    background: linear-gradient(45deg, rgb(32, 211, 64) 0%, rgb(0, 209, 237) 100%);
  }
  .footertitle {
    box-sizing: border-box;
    margin-top: 0;
    font-size: 14px;
    color: #fff;
    font-weight: normal;
    font-family: "Runda W00 Bold" !important;
    margin-bottom: 3px;
  }

  .footer_container {

    background-repeat: no-repeat;
    background-position: center 50px;
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
    
    @media (max-width: 990px) {
      padding-bottom: 20px;
    }

    @media (max-width: 500px) {
     .col {
          width: 100% !important;
          display: block;
          flex-basis: auto;
      }
    }





    
  }

  .subfooter {
    padding: 20px 0px;
    display: table;
    .web-links {
        float: left;
    }
    @media (max-width: 500px) {
     .web-links {
          width: 100%;
          text-align: center;
      }
      .social-links {
        width: 100%;
          text-align: center;
      }
    }
    .social-links {
        float: right;
    }
    .homepage {
        color: #fff;
    }
    li {
      display: inline-table;
      a{
        font-size: 13px;
        padding: 0px 6px;
        color: rgba(255, 255, 255, 0.75);
      }
    }
  }
`;

const List = styled.ul``;

const ListItem = styled.li`
  a {
    color: #fff;
    font-size: 12px;
    transition: all 0.2s ease;
    text-transform: capitalize;
    font-family: "Runda W00 medium" !important;
    &:hover,
    &:focus {
      outline: 0;
      text-decoration: none;
      color: rgba(255, 255, 255, 0.5);
    }
  }
`;

export { List, ListItem };

export default FooterWrapper;
