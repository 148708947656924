import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import NavbarWrapper from '../../../reusecore/src/elements/Navbar';
import Drawer from '../../../reusecore/src/elements/Drawer';
import Logo from '../../../reusecore/src/elements/UI/Logo';
import Box from '../../../reusecore/src/elements/Box';
import HamburgMenu from '../../../common/src/components/HamburgMenu';
import Container from '../../../common/src/components/UI/Container';
import { DrawerContext } from '../../../common/src/contexts/DrawerContext';
import ScrollSpyMenu from '../../../common/src/components/ScrollSpyMenu';
import { Row } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert'
import { Link, navigate } from "gatsby"
import './style.css';


const Navbar = ({ navbarStyle, logoStyle, row, menuWrapper }) => {
  const Data = useStaticQuery(graphql`
    query {
      allContentfulBlockSetting {
        edges {
          node {
            logo {
              fluid{
                src
              }
            }
            topBarText
          }
        }
      }
      allContentfulNavItem (filter:{menuPosition:{eq:"Main Menu"}}) {
        nodes {
          link
          caption
          pageLink
        }
      }

    }
  `);


  const logosite = Data.allContentfulBlockSetting.edges[0].node;
  const menuItems = Data.allContentfulNavItem.nodes;
  const { state, dispatch } = useContext(DrawerContext);
  console.log(menuItems);
  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  const [show, setShow] = useState(true);

  return (
    <NavbarWrapper {...navbarStyle}>
      <Container>
        <div className="main-nav">
          <Box {...row}>
            <Link to="/"><Logo
              logoSrc={logosite.logo.fluid.src}
              title="Controller"
              logoStyle={logoStyle}
            /></Link>
            <Box {...menuWrapper} className="menulinks">
              <ScrollSpyMenu
                className="main_menu"
                menuItems={menuItems}
                offset={-70}
              />
              <Drawer
                width="420px"
                placement="right"
                drawerHandler={<HamburgMenu barColor="#20d340" />}
                open={state.isOpen}
                toggleHandler={toggleHandler}
              >
                <ScrollSpyMenu
                  className="mobile_menu"
                  menuItems={menuItems}
                  drawerClose={true}
                  offset={-100}
                />
              </Drawer>
            </Box>
          </Box>
        </div>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    className: 'hosting_navbar',
    minHeight: '70px',
    display: 'block',
  },
  row: {
    flexBox: true,
    alignItems: 'center',
    justifyContent: [
      'space-between',
      'space-between',
      'space-between',
      'flex-start',
    ],
    width: '100%',

  },
  logoStyle: {
    maxWidth: '185px',
    mr: [0, 0, 0, '166px'],
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '600',
    color: 'white',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'primaryWithBg',
    minHeight: 'auto',
    height: `${1}`,
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center',
  },
};

export default Navbar;
